import { Component, Vue } from 'vue-property-decorator';
import Highcharts, { SeriesOptionsType } from 'highcharts';

@Component<Trendlijn>({})
export default class Trendlijn extends Vue {
  public $pageTitle = 'Trendlijn';

  protected chart: any;

  protected isLoading = false;

  protected years: string[] = [
    '2019',
    '2020',
  ];

  protected selectedYear = '2019';

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    this.initTrendlijn();
  }

  protected initTrendlijn() {
    const chartContainer = (this.$refs.trendlijn as any);
    if (! chartContainer) {
      return;
    }

    const weeks = [];
    for (let index = 1; index < 52; index += 1) {
      weeks.push(`${index}`);
    }

    this.chart = Highcharts.chart(chartContainer, {
      title: {
        text: `${this.selectedYear}`,
      },
      colors: ['#67A7C4', '#a09b1b', '#222222'],
      yAxis: {
        title: {
          text: 'Aantal',
        },
      },
      xAxis: {
        categories: weeks,
        title: {
          text: 'Week',
        },
      },
      legend: {
        layout: 'vertical',
        backgroundColor: '#FFFFFF',
        verticalAlign: 'bottom',
      },
      series: [
        {
          name: 'Productie',
          data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
        },
        {
          name: 'Afspraken',
          data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
        },
        {
          name: 'Beschikbaarheid',
          data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387],
        },
      ] as SeriesOptionsType[],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
          },
        }],
      },
    });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Trendlijn' },
        ],
      });
  }
}
