var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Trendlijn")])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "elementPanel elementPanel--spaced" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.years,
                              "item-value": "key",
                              "item-text": "name",
                              label: "Jaren"
                            },
                            on: {
                              change: function($event) {
                                return _vm.initTrendlijn()
                              }
                            },
                            model: {
                              value: _vm.selectedYear,
                              callback: function($$v) {
                                _vm.selectedYear = $$v
                              },
                              expression: "selectedYear"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "chartContainer" }, [
                    _c("div", { ref: "trendlijn" })
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }